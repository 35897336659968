import styled from 'styled-components';
import styles from './index.module.css';

const StyledPage = styled.div`
  .page {
  }
`;

export function Index() {
  /*
   * Replace the elements below with your own.
   *
   * Note: The corresponding styles are in the ./index.styled-components file.
   */
  return (
    <StyledPage>
       <div className={styles.page}>

        <div className="flex justify-center h-screen">
        <div className="flex flex-col justify-center">        
            <h1 className="text-6xl font-extrabold text-center">
              RecDeck
            </h1>
        </div>
        </div>

      </div>
    </StyledPage>
  );
}

export default Index;
